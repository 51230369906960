
// текст
export const langTextData = {
    defaultLang: 'ru',
    ru: {
        outMessage: {err: 'Ошибка загрузки файла', pro: 'В процессе ...', god: 'Файл успешно загружен'},
        formFileLoad: {firstLoad: {title: 'Загрузите файл xls', button: 'Загрузить'}},
        textInternal: {
            errorInternal: 'Время сессии истекло, попробуйте авторизоваться заново',
            successInternal: 'Для полноценной защиты ваших устройств скачайте и установите программу антивируса, которую вы найдёте в разделе «Мои подписки». <br /><br />* Программа входит в стоимость подписки.'
        }
    }
}

// пример данных которые получаются при запросе
export const textData = {blockDistributor: ['14','326'], showReport: true, productActions: false, addSearchId: true, ajaxUpdate: 200, unSubs: false, userEdit: true}

export const ajaxUpdate                 = 200
export const filterBrandProducts        = ['17','16','356']
