
let ajaxUpdate = 200

let lpTextData = {
    uBo: {
        ru: {
            yes: 'Да',
            no: 'Нет',
            exit: 'Выход',
            popup: {
                addData: 'введите данные, для подключения подписки',
                error: 'Ошибка',
                login: 'логин',
                loginNo: 'не заполнен логин',
                password: 'пароль',
                passwordNo: 'не заполнен пароль',
                authorization: 'Авторизация',
                titleExec: 'Выполнение',
                noData: 'У пользователя отсутствуют контактные данные',
                email: 'E-mail',
                emailNo: 'Не заполнен E-mail',
                emailErr: 'Не корректный E-mail',
                phone: 'Телефон',
                phoneNo: 'Не заполнен Телефон',
                close: 'Закрыть',
                buy: 'Подключить',
                addSubs: 'Подключить подписку',
                suspendSubs: 'Отменить подписку',
                variant1: 'Есть другой антивирус',
                variant2: 'Есть проблемы с установкой и активацией',
                variant3: 'Не устраивает цена',
                variant4: 'Абонент не давал согласие на подключение',
                variant5: 'Другое',
                variant5reason: 'укажите причину',
                titleErr: 'Произошла ошибка',
                tableHistDate: 'Дата изменения',
                tableHistAction: 'Действие',
                tableHistInit: 'Инициатор',
            },
            panel: {
                titleMain: 'Панель управления подписками',
                user: 'Пользователь',
            },
            searchUser: {
                titleMain: 'Поиск абонентов',
                userIdTitle: 'абонент',
                userIdPlace: 'Введите id абонента',
                userUuidTitle: 'Договор',
                userUuidPlace: 'Введите № договора',
                search: 'Поиск',

                titleUser: 'Данные абонента',
                family: 'Фамилия',
                name: 'Имя',
                doc: 'Договор',
                email: 'E-mail',
                phone: 'Телефон',
                balance: 'Баланс',
            },
            subsList: {
                titleMain: 'Действующие подписки абонента',
                tableProduct: 'Продукт',
                tableStatus: 'Статус',
                tableConnectOn: 'Подключена',
                tableConnectOff: 'Активна&nbsp;до',
                tableAction: 'Действие',
                tableHistory: 'История&nbsp;операций',
                tableHistoryDate: 'Дата изменения',
                tableHistoryAction: 'Действие',
                tableHistoryInitiator: 'Инициатор',
                tableActionAdd: 'Восстановить',
                tableActionRemove: 'Отключить',
                tableHit: 'хит',
                tableAutoProlong: 'Данную подписку нельзя приостанавливать / возобновлять',
                tableClose: 'Отключить',
                tableOpen: 'Возобновить',
                tableNoSubs: 'действующих подписок нет',
            },
            itemList: {
                titleMain: 'Продукты, доступные для подключения',
                tableProduct: 'Продукт',
                tableTrial: 'Триал&nbsp;(дней)',
                tablePrice: 'Стоимость',
                tableAction: 'Действие',
                tableHit: 'хит',
                tableAdd: 'Подключить',
                tableNoProducts: 'продуктов нет',
            },
            err: {
                head: 'КАТАЛОГ АНТИВИРУСОВ ВРЕМЕННО НЕДОСТУПЕН',
                title: 'Пожалуйста, попробуйте обновить страницу или зайдите позднее. Приносим извинения за доставленные неудобства.',
                refresh: 'Обновить страницу',
            },
            footer: {
                poweredBy: 'Разработано',
                support: 'Служба поддержки',
            },
        },
        en: {
            yes: 'Yes',
            no: 'No',
            exit: 'Exit',
            popup: {
                addData: 'enter data to connect the subscription',
                error: 'Error',
                login: 'login',
                loginNo: 'login is empty',
                password: 'password',
                passwordNo: 'password is empty',
                authorization: 'Authorization',
                titleExec: 'Executing',
                noData: 'The user has no contact information',
                email: 'E-mail',
                emailNo: 'E-mail not filled in',
                emailErr: 'Incorrect E-mail',
                phone: 'Phone',
                phoneNo: 'Not filled in Phone number',
                close: 'Close',
                buy: 'Buy',
                addSubs: 'Get a subscription',
                suspendSubs: 'Unsubscribe',
                variant1: "There's another antivirus",
                variant2: 'There are problems with installation and activation',
                variant3: 'Not satisfied with the price',
                variant4: 'Subscriber did not give consent for connection',
                variant5: 'Other',
                variant5reason: 'state the reason',
                titleErr: 'Error',
                tableHistDate: 'Date of change',
                tableHistAction: 'Action',
                tableHistInit: 'Initiator',
            },
            panel: {
                titleMain: 'Subscription control panel',
                user: 'User',
            },
            searchUser: {
                titleMain: 'Search Subscribers',
                userIdTitle: 'subscriber',
                userIdPlace: 'Enter subscriber id',
                userUuidTitle: 'Contract',
                userUuidPlace: 'Enter contract №',
                search: 'Search',

                titleUser: 'Subscriber details',
                family: 'Last name',
                name: 'First Name',
                doc: 'Contract',
                email: 'E-mail',
                phone: 'Phone',
                balance: 'Balance',
            },
            subsList: {
                titleMain: "Subscriber's valid subscriptions",
                tableProduct: 'Product',
                tableStatus: 'Status',
                tableConnectOn: 'Connected',
                tableConnectOff: 'Active&nbsp;until',
                tableAction: 'Action',
                tableHistory: 'Transaction&nbsp;History',
                tableHistoryDate: 'Change Date',
                tableHistoryAction: 'Action',
                tableHistoryInitiator: 'Initiator',
                tableActionAdd: 'Restore',
                tableActionRemove: 'Disable',
                tableHit: 'hit',
                tableAutoProlong: 'This subscription cannot be suspended / resume',
                tableClose: 'Suspend',
                tableOpen: 'Resume',
                tableNoSubs: 'no active subscriptions',
            },
            itemList: {
                titleMain: 'Products available for connection',
                tableProduct: 'Product',
                tableTrial: 'Trial&nbsp;(days)',
                tablePrice: 'Price',
                tableAction: 'Action',
                tableHit: 'hit',
                tableAdd: 'Connect',
                tableNoProducts: 'no products available',
            },
            err: {
                head: 'ANTIVIRUS CATALOG IS TEMPORARILY UNAVAILABLE',
                title: 'Please try refreshing the page or come back later. We apologize for the inconvenience.',
                refresh: 'Refresh the page',
            },
            footer: {
                poweredBy: 'Developed by',
                support: 'Support Service',
            },
        },
    },
    uFrame: {
        ru: {
            nBlock: {
                aPay: 'Пополнения счета',
                lk: 'Личный кабинет',
                prom: 'Акции',
                tv1: 'Цифровое',
                tv2: 'телевидение',
                hi1: 'Домашний',
                hi2:'интернет',
                mi1: 'Мобильный',
                mi2: 'интернет',
                re1: 'Дополнительные',
                re2: 'услуги',
                wtv: 'Беспроводное ТВ',

                lte: 'О технологии LTE',
                how: 'Как использовать',
                vMap: 'Карта покрытия',
                hLte: 'Подключить Интернет LTE',
                sPack: 'Пакеты услуг',
                sDoc: 'Документы',
            },
            mainBlocks: {
                bSubs: 'Текущие подписки',
                bHit: 'Самые популярные продукты',
                bProd: 'Полный список продуктов',
            },
            popup: {
                mainHeadTitle: 'Подтверждение',
                mainTitle: 'Введите почту и телефон для получения продукта',
                inputPlaceEmail: 'Введите e-mail',
                inputOfferTitleBefore: 'Я согласен с условиями',
                inputOfferTitleAfter: 'оферты',
                suspendTitle: 'Укажите причину отключения',
                suspend17: 'Есть другой антивирус',
                suspend18: 'Проблемы с установкой и активацией',
                suspend19: 'Не устраивает цена',
                suspend20: 'Другое',
                procSendTitle: 'Отправка данных',
                procReturnErr: 'Ошибка',
                procReturnRefresh: 'Обновить страницу',
            },
            pCard: {
                dCreate: 'Дата активации',
                dActive: 'Дата продления',
                instruction: 'Инструкция',
                instructions: 'Инструкции',
                instructionInstall: 'Инструкция по установке',
                pCode: 'Код активации',
                pLogin: 'Логин',
                pPass: 'Пароль',
                pKeyWait: 'Дистрибутивы появятся в течении нескольких минут',
                pProgram: 'Программа',
                pProgramFor: 'Версия для',
                inTariff: 'В тарифе',
                inControl: 'Управление',
                flopSubs: 'Подключить',
                flopSuspend: 'Отключить',
                flopResume: 'Возобновиить',
            },
            afterSubs: {
                successSubs: 'успешно подключен!',
                successKeyWait: 'Ключ появится через пару минут',
            },
            preSubsCheck: {
                errEmail: 'Не корректный E-mail',
                errPhone: 'Не корректный Телефон',
                errOffer: 'Не приняты условия оферты',
                errTitle: 'Не заполнено описание проблемы',
            },
            action: {activate: 'Подключить', suspend: 'Отключить', resume: 'Возобновить', nextStep: 'Подтверждаю'},
            extPropDevices: {
                protectFrom: 'Лицензионная защита на',
                protectDevices: 'устройств',
                protectDevicesOne: 'о',
                protectDevicesAll: 'а',
                drWeb: {
                    splitTo: 'защита для Android',
                },
                protectDevicesAllPlatform: 'Защита любых устройств на платформах',
            },
            popupMessage: {errorInternal: 'Время сессии истекло, попробуйте авторизоваться заново', successInternal: 'Для полноценной защиты ваших устройств скачайте и установите программу антивируса, которую вы найдёте в разделе «Мои подписки». <br /><br />*Программа входит в стоимость подписки.'},
        },
        en: {
            nBlock: {
                aPay: 'Account top-ups',
                lk: 'Personal account',
                prom: 'Promotions',
                tv1: 'Digital',
                tv2: 'television',
                hi1: 'Home',
                hi2: 'internet',
                mi1: 'Mobile',
                mi2: 'internet',
                re1: 'Additional',
                re2: 'services',
                wtv: 'Wireless TV',

                lte: 'About LTE technology',
                how: 'How to use',
                vMap: 'Coverage map',
                hLte: 'Connect LTE Internet',
                sPack: 'Service packages',
                sDoc: 'Documents',
            },
            mainBlocks: {
                bSubs: 'Current Subscriptions',
                bHit: 'Most Popular Products',
                bProd: 'Full List of Products',
            },
            popup: {
                mainHeadTitle: 'Confirmation',
                mainTitle: 'Enter your email and phone number to receive the product',
                inputPlaceEmail: 'Enter your email',
                inputOfferTitleBefore: 'I agree to the terms',
                inputOfferTitleAfter: 'offers',
                suspendTitle: 'Specify the reason for disabling',
                suspend17: 'There is another antivirus',
                suspend18: 'Problems with installation and activation',
                suspend19: 'The price is not satisfactory',
                suspend20: 'Other',
                procSendTitle: 'Sending data',
                procReturnErr: 'Error',
                procReturnRefresh: 'Refresh the page',
            },
            pCard: {
                dCreate: 'Activation date',
                dActive: 'Renewal date',
                instruction: 'Instruction',
                instructions: 'Instructions',
                instructionInstall: 'Installation instructions',
                pCode: 'Activation code',
                pLogin: 'Login',
                pPass: 'Password',
                pKeyWait: 'Distributions will appear in a few minutes',
                pProgram: 'Program',
                pProgramFor: 'Version for',
                inTariff: 'In tariff',
                inControl: 'Control',
                flopSubs: 'Connect',
                flopSuspend: 'Disconnect',
                flopResume: 'Resume',
            },
            afterSubs: {
                successSubs: 'connected successfully!',
                successKeyWait: 'The key will appear in a couple of minutes',
            },
            preSubsCheck: {
                errEmail: 'Incorrect E-mail',
                errPhone: 'Incorrect Phone',
                errOffer: 'Offer terms not accepted',
                errTitle: 'Problem description not filled in',
            },
            action: {activate: 'Activate', suspend: 'Suspend', resume: 'Resume', nextStep: 'Confirm'},
            extPropDevices: {
                protectFrom: 'License protection for',
                protectDevices: 'devices',
                protectDevicesOne: '',
                protectDevicesAll: '',
                drWeb: {
                    splitTo: 'protection for Android',
                },
                protectDevicesAllPlatform: 'Protection of any devices on platforms',
            },
            popupMessage: {errorInternal: 'Session timed out, please try to log in again', successInternal: 'For full protection of your devices, download and install the antivirus program that you will find in the "My Subscriptions" section. <br /><br />*The program is included in the subscription price.'},
        },
    },
    uUpload: {
        fileUploadFormat: ['xls','xlsx'],
            ru: {
            outMessage: {err: 'Ошибка загрузки файла', pro: 'В процессе ...', god: 'Файл успешно загружен'},
            formFileLoad: {firstLoad: {title: 'Загрузите файл xls', button: 'Загрузить'}},
        },
        tr: {
            outMessage: {err: 'File upload error', pro: 'Processing ...', god: 'Dosya başarılı şekilde yüklendi. İşleme sonuçları için lütfen e-postanızı kontrol edin.',},
            formFileLoad: {firstLoad: {title: 'Yeni üyelik için XLS dosyasını yükleyin', button: 'Yeni üyelikleri yükleyin',}, noFirstLoad: {title: 'İptal edilmiş üyelik için XLS dosyasını yükleyin', button: 'İptal edilmiş üyelikleri yükleyin',},}
        },
    },
}

let descIcon = {
    deletedItems : [101,102,103,104,109,110,111,112,113,115,119,124,125,127,155,175],

    1 : {icon: "icon-icons-01"},
    2 : {icon: "icon-icons-02"},
    3 : {icon: "icon-icons-03"},
    4 : {icon: "icon-icons-04"},
    5 : {icon: "icon-icons-03"},

    105 : {icon: "icon-icons-06", ru: 'Оптимален для активных интернет-пользователей', en: 'Ideal for active Internet users'},
    106 : {icon: "icon-icons-13", ru: 'Безопасные платежи в интернете', en: 'Secure online payments'},
    107 : {icon: "icon-icons-05", ru: 'Функция "Родительский контроль"', en: 'Parental control function'},
    108 : {icon: "icon-icons-12", ru: 'Шифрование файлов и управление паролями', en: 'File encryption and password management'},
    114 : {icon: "icon-icons-06", ru: 'Быстрый проактивный антивирус', en: 'Fast proactive antivirus'},
    116 : {icon: "icon-icons-14", ru: 'Защищает от интернет-угроз', en: 'Protects against Internet threats'},
    117 : {icon: "icon-icons-15", ru: 'Помогает контролировать время использования устройства', en: 'Helps control device usage time'},
    118 : {icon: "icon-icons-16", ru: 'Позволяет узнать местонахождение устройства', en: 'Lets you know the location of the device'},
    120 : {icon: "icon-icons-18", ru: 'Анти-баннер', en: 'Anti-banner'},

    121 : {icon: "icon-icons-15", ru: 'Контроль приложений и времени', en: 'Application and time control'},
    122 : {icon: "icon-icons-16", ru: 'Определение местоположения', en: 'Location determination'},
    123 : {icon: "icon-icons-17", ru: 'Сообщение ребенку', en: 'Message to a child'},
    128 : {icon: "icon-icons-28", ru: 'Обнаруживает во входящих звонках неизвестные номера (интернет-соединение не требуется)', en: 'Detects unknown numbers in incoming calls (no internet connection required)'},
    129 : {icon: "icon-icons-29", ru: 'Предоставляет подробные сведения о неизвестных номерах', en: 'Provides detailed information about unknown numbers'},
    130 : {icon: "icon-icons-30", ru: 'Блокирует нежелательные звонки и сообщает информацию о них', en: 'Blocks unwanted calls and reports information about them'},
    132 : {icon: "icon-icons-32", ru: 'Непрерывно повышает точность обнаружения спама за счет обновления антиспам-баз', en: 'Continuously improves spam detection accuracy by updating anti-spam databases'},
    133 : {icon: "icon-icons-33", ru: 'Работы выполняются удаленно', en: 'Work is done remotely'},
    138 : {icon: "icon-icons-38", ru: 'Облачная защита', en: 'Cloud protection'},
    139 : {icon: "icon-icons-39", ru: 'Технология безопасного соединения (Kaspersky Secure Connection)', en: 'Secure connection technology (Kaspersky Secure Connection)'},
    140 : {icon: "icon-icons-40", ru: 'Резервное копирование', en: 'Backup'},

    141 : {icon: "icon-icons-41", ru: 'Менеджер паролей', en: 'Password manager'},
    142 : {icon: "icon-icons-42", ru: 'Программа "Safe Kids"', en: 'Program "Safe Kids"'},
    143 : {icon: "icon-icons-43", ru: 'Защита конфиденциальности', en: 'Privacy Protection'},
    151 : {icon: "icon-icons-51", ru: 'Контроль состояния ПК', en: 'PC Health Monitoring'},
    153 : {icon: "icon-icons-53", ru: 'Очистка системы', en: 'Cleaning the system'},
    154 : {icon: "icon-icons-54", ru: 'Чат с технической поддержкой онлайн', en: 'Chat with technical support online'},
    157 : {icon: "icon-icons-57", ru: 'Разовое обращение', en: 'One-time treatment'},

    161 : {icon: "icon-icons-15", ru: 'Антивирус в режиме реального времени', en: 'Antivirus in real time'},
    162 : {icon: "icon-icons-26", ru: 'Интернет-защита', en: 'Internet protection'},
    163 : {icon: "icon-icons-49", ru: 'Восстановление зараженного компьютера', en: 'Recovery of an infected computer'},
    164 : {icon: "icon-icons-20", ru: 'Анти-Фишинг', en: 'Anti-Phishing'},
    165 : {icon: "icon-icons-39", ru: 'Защита от шифровальщиков', en: 'Ransomware Protection'},
    166 : {icon: "icon-icons-24", ru: 'Сетевой экран и Защита от сетевых атак', en: 'Firewall and Network Attack Blocker'},
    167 : {icon: "icon-icons-06", ru: 'Инструменты оптимизации', en: 'Optimization tools'},
    168 : {icon: "icon-icons-25", ru: 'Освобождение места на устройстве', en: 'Free up space on your device'},
    169 : {icon: "icon-icons-40", ru: 'Игровой режим и режим Не беспокоить', en: 'Game Mode and Do Not Disturb Mode'},

    170 : {icon: "icon-icons-48", ru: 'Управление приложениями', en: 'Application management'},
    171 : {icon: "icon-icons-46", ru: 'Обнаружение сталкерских приложений', en: 'Detection of stalker apps'},
    172 : {icon: "icon-icons-23", ru: 'Контроль камеры и микрофона', en: 'Camera and microphone control'},
    173 : {icon: "icon-icons-29", ru: 'Поиск небезопасных настроек', en: 'Finding insecure settings'},
    174 : {icon: "icon-icons-43", ru: 'Удаление неиспользуемых приложений', en: 'Removing unused applications'},
    176 : {icon: "icon-icons-45", ru: 'Приватность в социальных сетях', en: 'Social media privacy'},
    177 : {icon: "icon-icons-16", ru: 'Поиск устройства', en: 'Device search'},
    178 : {icon: "icon-icons-46", ru: 'Защита от криптоугроз', en: 'Protection against crypto threats'},
    179 : {icon: "icon-icons-50", ru: 'Премиум-версия Kaspersky Password Manager', en: 'Premium version of Kaspersky Password Manager'},

    180 : {icon: "icon-icons-21", ru: 'Защита от звонков спамеров и мошенников', en: 'Protection against calls from spammers and scammers'},
    182 : {icon: "icon-icons-51", ru: 'Проверка безопасности паролей', en: 'Password security check'},
    183 : {icon: "icon-icons-29", ru: 'Хранилище документов', en: 'Document storage'},
    184 : {icon: "icon-icons-20", ru: 'Обнаружение удаленного доступа', en: 'Remote access discovery'},
    185 : {icon: "icon-icons-17", ru: 'Расширенная техподдержка', en: 'Extended technical support'},
    186 : {icon: "icon-icons-14", ru: 'Приоритетная линия техподдержки', en: 'Priority support line'},
    187 : {icon: "icon-icons-13", ru: 'Безопасные платежи', en: 'Secure payments'},

    191 : {icon: "icon-icons-15", ru: 'Антивирусная защита в режиме реального времени', en: ''},
    192 : {icon: "icon-icons-13", ru: 'Защита платежей', en: ''},
    193 : {icon: "icon-icons-06", ru: 'Оптимизация производительности', en: ''},
    194 : {icon: "icon-icons-19", ru: 'Определитель номера Who Calls', en: ''},
    195 : {icon: "icon-icons-19", ru: 'Поиск утечек данных', en: ''},
}

export {
    ajaxUpdate, lpTextData, descIcon,
}
